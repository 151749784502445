<template>
    <div class="stepsdemo-content">
        <Card>
            <template v-slot:title>
                Paso 5 de 5
            </template>
            <template v-slot:subtitle>
                Resumen de Preinscripción
            </template>
            <template v-slot:content>
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12 p-md-12"> 
						<h4><strong>Datos Personales</strong></h4>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-2">
								Nº Cedula: <strong>{{formData.estudiante.nac.code}}{{formData.estudiante.cedula}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-2">
								Sexo: <strong v-if="formData.estudiante.sexo">Masculino</strong>
								<strong v-else>Femenino</strong>
							</div>
							<div class="p-field p-col-12 p-md-8">
								Nombres y Apellidos: <strong>{{formData.estudiante.nombre}} {{formData.estudiante.apellido}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-8">
								Correo: <strong>{{formData.estudiante.correo}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-4">
								Fecha de Nacmiento: <strong>{{convfech(formData.estudiante.fecha_nac)}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-8">
								Lugar de Nacimiento: <strong>{{formData.estudiante.lugar_nac.nombre}} ({{formData.estudiante.pais_nac.nombre}})</strong>
							</div>				
						</div>
					</div>
                    <div class="p-field p-col-12 p-md-12">
						<h4>Extensión: <strong>{{formData.matricula.extension.nombre}}</strong></h4>
					</div>		
                    <div class="p-field p-col-12 p-md-8">
						<h4>Carrera: <strong>{{formData.matricula.plan.nombre}}</strong></h4>
					</div>		
                    <div class="p-field p-col-12 p-md-4">
						<h4>Turno: <strong>{{formData.matricula.turno.nombre}}</strong></h4>
					</div>		
                    <div class="p-field p-col-12 p-md-12"> 
						<h4><strong>Factura</strong></h4>
						<DataTable :value="formData.factura" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
							<Column header="Fecha">	
								<template #body="{data}">
									{{data.fecha}}
								</template>
							</Column>
							<Column header="Descripción">	
								<template #body="{data}">
									{{data.nombre}}
								</template>
							</Column>
							<Column header="Monto">	
								<template #body="{data}">
									<strong v-if="formData.moneda==1">Ref.</strong>
                                    <img :src="'images/moneda'+formData.moneda+'.png'" width="15" v-if="formData.moneda"/>
									{{data.monto}}
								</template>
							</Column>
							<Column header="Monto Bs">	
								<template #body="{data}">
                                    <div v-if="data.moneda==0">Bs. {{data.monto}}</div>
                                    <div v-if="data.moneda==1">Bs. {{data.monto}}</div>
                                    <div v-if="data.moneda==2">Bs. {{new Intl.NumberFormat("de-DE").format((data.monto*formData.tasa).toFixed(2))}}</div>
								</template>
							</Column>
						</DataTable>
					</div>
					<div class="p-col-7"></div>
					<div class="p-col-2"><h4><strong>Total:</strong></h4></div>
					<div class="p-col-3"><h4><strong>
                        Bs. {{new Intl.NumberFormat("de-DE").format((formData.factura_montobs).toFixed(2))}}
                    </strong></h4></div>
                    <div class="p-field p-col-12 p-md-12"> 
						<h4>
							<strong>Pagos</strong>
							<strong style="color: red;" v-if="formData.pago_montobs!=formData.factura_montobs">
								(Pago en proceso de validación)
							</strong>
						</h4>
						<DataTable :value="formData.pagos" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
							<Column header="Fecha">	
								<template #body="{data}">
									{{data.fecha}}
								</template>
							</Column>
							<Column header="Tipo">	
								<template #body="{data}">
									{{data.tipo.nombre}}
								</template>
							</Column>
							<Column header="Banco">	
								<template #body="{data}">
									{{data.banco_orig.nombre}}
								</template>
							</Column>
							<Column header="Referencia">	
								<template #body="{data}">
									{{data.referencia}}
								</template>
							</Column>
							<Column header="Monto">	
								<template #body="{data}">
									<strong v-if="formData.moneda==1">Ref.</strong>
                                    <img :src="'images/moneda'+formData.moneda+'.png'" width="15" v-if="formData.moneda"/>
									{{(data.monto).toFixed(2)}}
								</template>
							</Column>
							<Column header="Monto Bs">	
								<template #body="{data}">
                                    Bs. {{new Intl.NumberFormat("de-DE").format((data.montobs).toFixed(2))}}
                                </template>
							</Column>
						</DataTable>                   
					</div>
					<div class="p-col-7"></div>
					<div class="p-col-2"><h4><strong>Total:</strong></h4></div>
					<div class="p-col-3"><h4><strong>
                        Bs. {{new Intl.NumberFormat("de-DE").format((formData.pago_montobs).toFixed(2))}}
                    </strong></h4></div>
                </div>
            </template>
            <template v-slot:footer>
				<div class="p-grid p-justify-center">
					<h5><strong>CONDICIONES PARA EL PROCESO DE PREINSCRIPCIÓN</strong></h5>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-2"></div>
						<div class="p-field p-col-8">
							<div class="p-text-justify">
								<div v-html="formData.compromiso"></div>
							</div>
						</div>
						<div class="p-field p-col-3"></div>
						<div class="p-field p-col-6">
							<Checkbox id="accept" name="accept" value="Accept" v-model="aceptar" :class="{'p-invalid':aceptar}" />
							<strong> Manifiesto haber leído y ACEPTO las cláusulas a las que está sujeto el proceso de preinscripción para alumnos nuevos del Instituto Universitario de Tecnología Industrial “Rodolfo Loero Arismendi” (IUTIRLA).</strong>
						</div>
					</div>
					<br>
					<small v-show="validationErrors.conf" style="color: red;">Debe aceptar los términos para continuar</small>
                </div>
                <div class="p-grid p-nogutter p-justify-between">
                    <Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left" />
                    <Button label="Confirmar" @click="complete()" icon="pi pi-check" iconPos="right" class="p-button-success" :disabled="procesando==true"/>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import API from "@/service/API";
const Consulta = new API('Preinscripcion','Procesar');

export default {
    props: {
        formData: Object
    },
    data () {
        return {
			size: '60vw',
            validationErrors: {},
			aceptar: false,
			procesando: false
        }
    },
	created() {
		this.$store.commit('Loading');
	},
    methods: {
        prevPage() {
            this.$emit('prev-page', {pageIndex: 4});
        },
        complete() {
			if (this.validateForm()) {
				this.procesando = true;
				Consulta.Procesar('INS',{		
					factura: this.formData.factura[0],
					pagos: this.formData.pagos, 
					factura_montobs: this.formData.factura_montobs,
					factura_monto: this.formData.factura_monto,
					pago_montobs: this.formData.pago_montobs, 
					matricula: this.formData.matricula,
					estudiante: this.formData.estudiante,
					academico: this.formData.academico,
					moneda: this.formData.moneda,
					tasa: this.formData.tasa,
				}).then(response => {
					//this.$store.state.error =response;
					if (response.result) {
						this.$toast.add({
							severity:'success',
							summary: 'Éxito',
							detail: 'Información Procesada', 
							life: 3000
						});
						this.procesando = false;
						this.$store.commit('Loading');
						this.$router.push({ path: '/preinscripcion'});
					} else {
						this.$toast.add({
							severity:'warn',
							summary: 'Advertencia',
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
						this.procesando = false;
						this.$store.commit('Loading');
					}
				});
			}
        },
        convfech(fecha){
            return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
		},
        validateForm() {
            if (this.aceptar!='Accept')
                this.validationErrors['conf'] = true;
            else
                delete this.validationErrors['conf'];
            return !Object.keys(this.validationErrors).length;
        }

    }
}
</script>